import { Global, css, useTheme } from '@emotion/react';
import { readableColor }         from 'polished';

export const GlobalStyle = () => {
  const theme = useTheme();
  return <Global styles={css`
    h1, h2, h3, h4, h5, h6 {
      hyphens: auto;
      ${theme.config.global.headingFontOverride !== 'unset' ? `font-family: ${theme.config.global.headingFontOverride}` : null};
    }

    body {
      background-color: ${theme.config.global.backgroundColor} !important;
      background: ${theme.config.global.backgroundColor} !important;
      color: ${readableColor(theme.base_color, 'rgba(0,0,0, 0.7)', 'rgba(255,255,255, 0.7)')} !important;

      h1, h2, h3, h4, h5, h6 {
        color: ${readableColor(theme.base_color, 'rgba(0,0,0, 0.8)', 'rgba(255,255,255, 0.8)')} !important;
      }
    }

    a {
      color: ${theme.brand_color};
      background-image: none;
      text-decoration: none;
      text-shadow: none;

      &:hover {
        text-decoration: underline;
      }
    }

    // Hotfix
    .gatsby-resp-image-background-image {
      padding-bottom: 0 !important;
    }
  `}/>;
};